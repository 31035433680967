import React, {useEffect, useState} from "react";

const SelectedProductContext = React.createContext({
    selectedProducts: '',
    setSelectedProducts: () => {}
});


const SelectedProductProvider = ({children}) => {
    const [selectedProducts, setSelectedProducts] = useState(
        JSON.parse(localStorage.getItem('selectedProducts')) || []
    );

    useEffect(() => {
        localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
    }, [selectedProducts]);

    return (
        <SelectedProductContext.Provider
            value={{
                selectedProducts,
                setSelectedProducts,
            }}
        >
            {children}
        </SelectedProductContext.Provider>
    )

}
export default SelectedProductContext;
export { SelectedProductProvider };